/* PostPagePost - The specific Post that appears on the post details page */
import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useRouter } from 'next/router';
import {
  useMutation,
  OperationVariables,
  ApolloQueryResult,
} from '@apollo/client';
import Icon from 'koba/components/Icon';

import ReactionButton from 'components/ReactionButton';
import { getReactors } from 'helpers/reactionHelpers';

import {
  BREAKPOINT_MD,
  REACTION_TYPES,
  NUMBER_OF_REACTIONS,
} from 'utils/constants';
import TrackingEvent from 'constants/trackingEvents';

import { goToPostPage } from 'utils/routerHelpers';
import { DANGER_TYPE } from 'koba/components/InlineActions/constants';
import { useCommunityContext } from 'components/CommunityContext';
import { canDeletePost, canEditPost, canPinPost } from 'utils/userHelpers';
import { relativeFormattedDateTime } from 'helpers/dateHelpers';
import { PIN_POST_MUTATION, UNPIN_POST_MUTATION } from 'schema/Post/mutations';
import { useWindowSize } from 'hooks/useWindowSize';

import { TFunction } from 'next-i18next';
import {
  MentionedUsersFieldFragment,
  PostListQuery,
} from '__generated__/graphql/legacy/graphql';
import { PostListUser } from 'types/user';
import { Post as PostType, PostAction } from 'types/post';

import PostContent from './components/PostContent';
import PostHeader from './components/PostHeader';
import CommentSection from './components/CommentSection';

import styles from './Post.module.scss';

const cx = classNames.bind(styles);

interface PostProps {
  additionalClassName?: string | null;
  communityWrapperRef?: React.RefObject<HTMLDivElement>;
  content: string;
  lastCommentRef?: React.RefObject<HTMLDivElement>;
  mentionedUsers?: MentionedUsersFieldFragment | null;
  post: PostType;
  postReactionCount: number;
  refetchPostList?: (
    variables?: Partial<OperationVariables>
  ) => Promise<ApolloQueryResult<PostListQuery>>;
  shouldShowTagOnPost?: boolean;
  t: TFunction;
  time: string;
  totalCommentCount: number;
  author?: PostListUser | null;
  onPostChange?(): void;
  handleDeletePost(event): void;
  handleEditPost(event): void;
  trackPostEvent(event): void;
}

const PostPagePost: React.FC<PostProps> = ({
  additionalClassName = '',
  content,
  mentionedUsers,
  author,
  post,
  time,
  t,
  refetchPostList,
  totalCommentCount,
  postReactionCount,
  lastCommentRef = {},
  communityWrapperRef = {},
  shouldShowTagOnPost = false,
  onPostChange,
  handleDeletePost,
  handleEditPost,
  trackPostEvent,
}) => {
  const { community, currentUser } = useCommunityContext();

  const firstAttachment = post?.postAttachments?.[0];

  const [pinPost, { loading: pinLoading }] = useMutation(PIN_POST_MUTATION);
  const [unpinPost, { loading: unpinLoading }] = useMutation(
    UNPIN_POST_MUTATION
  );
  const { width } = useWindowSize();
  const isMobile = width < BREAKPOINT_MD;
  const [shouldClampContent, setShouldClampContent] = useState<boolean>(
    (content?.length || 0) > 480
  );

  const router = useRouter();
  const commentIdFromURL = router && router.query && router.query.comment;

  const goToPost = () => {
    goToPostPage(router, community.id, post.id, true, true);
  };

  const canUserEditPost = canEditPost(currentUser, author);
  const canUserDeletePost = canDeletePost(currentUser);
  const canUserPinPost = canPinPost(currentUser);

  const postRef = React.useRef<HTMLDivElement>(null);
  const creationDateTime = relativeFormattedDateTime(
    time,
    currentUser?.locale,
    t
  );

  const onCommentSubmitted = () => {
    onPostChange?.();
    if (commentIdFromURL) {
      goToPost();
    }
  };

  const postActionsList: PostAction[] = [];

  if (canUserEditPost) {
    postActionsList.push({
      name: 'edit',
      text: t('common-edit', 'Edit'),
      onClick: handleEditPost,
    });
  }

  if (canUserPinPost) {
    if (post.pinnedAt && !unpinLoading) {
      postActionsList.push({
        name: 'unpin',
        text: t('Unpin'),
        onClick: () =>
          unpinPost({
            variables: { id: post.id, numberOfReactions: NUMBER_OF_REACTIONS },
          }).then(() => {
            onPostChange?.();
            trackPostEvent(TrackingEvent.UNPIN);
            refetchPostList?.({ shouldShowLoading: true });
          }),
      });
    } else if (!pinLoading) {
      postActionsList.push({
        name: 'pin',
        text: t('Pin'),
        onClick: () =>
          pinPost({
            variables: { id: post.id, numberOfReactions: NUMBER_OF_REACTIONS },
          }).then(() => {
            onPostChange?.();
            trackPostEvent(TrackingEvent.PIN);
            refetchPostList?.({ shouldShowLoading: true });
          }),
      });
    }
  }

  if (canUserDeletePost) {
    postActionsList.push({
      name: 'delete',
      text: (
        <div className={cx('post__detail-action')}>
          <Icon className={cx('post__detail-action__icon')} name="trash-can" />
          <span>{t('Delete post')}</span>
        </div>
      ),
      title: t('Delete post'),
      onClick: handleDeletePost,
      type: DANGER_TYPE,
    });
  }

  const hasAttachment = post.postAttachments && post.postAttachments[0];

  return (
    <div className={cx('post')}>
      <div
        className={cx('post__container', 'post__container--full')}
        ref={postRef}
      >
        <div
          className={cx('post__detail', {
            'is-spaces-enabled': shouldShowTagOnPost, // we should try and avoid coupling feature names to classes
          })}
          id={post.id}
          role="article"
        >
          <div
            className={cx('post__body', {
              'is-spaces-enabled': shouldShowTagOnPost,
            })}
          >
            <PostHeader
              author={author}
              creationDateTime={creationDateTime || ''}
              editedAt={post.editedAt}
              isFollowing={post.isFollowing}
              isMobile={isMobile}
              pinnedAt={post.pinnedAt}
              postActionsList={postActionsList}
              showFollowingTag={false}
              title={post.title || ''}
            />
            <PostContent
              content={content}
              firstAttachment={firstAttachment}
              hasAttachment={!!hasAttachment}
              isPostPage={false}
              mentionedUsers={mentionedUsers}
              setShouldClampContent={(shouldClamp) => {
                setShouldClampContent(shouldClamp);
              }}
              shouldClampContent={shouldClampContent}
              shouldScroll={false}
              t={t}
            />
          </div>

          <div className={cx('post__info')}>
            <ReactionButton
              hasReacted={post.hasReacted}
              id={post.id}
              isReactingTo="post"
              postReactionCount={postReactionCount}
              reactionType={REACTION_TYPES.LIKE_POST}
              reactors={getReactors(post)}
              t={t}
            />
            <div className={cx('total-comments')} data-qa="main-comments_text">
              <Icon name="discussions" />
              <span>
                {t('{{count}} comment', { count: totalCommentCount })}
              </span>
            </div>
            <hr className={cx('post__separator')} />
          </div>

          <CommentSection
            additionalClassName={additionalClassName || ''}
            author={author}
            communityWrapperRef={communityWrapperRef}
            lastCommentRef={lastCommentRef}
            post={post}
            postRef={postRef}
            onCommentSubmitted={onCommentSubmitted}
          />
        </div>
      </div>
    </div>
  );
};

export default PostPagePost;

import React, { useState } from 'react';
import {
  MentionedUsersFieldFragment,
  PostListQuery,
} from '__generated__/graphql/legacy/graphql';
import { Post as PostType } from 'types/post';

import { TFunction } from 'next-i18next';
import { PostListUser } from 'types/user';
import { ApolloQueryResult, OperationVariables } from '@apollo/client';

import { useCommunityContext } from 'components/CommunityContext';
import { Loader, toaster } from '@thinkific/toga-react';
import dynamic from 'next/dynamic';
import { useWindowSize } from 'hooks/useWindowSize';
import {
  BREAKPOINT_MD,
  EVENT_POST_TYPES,
  SUCCESS_TOAST,
} from 'utils/constants';
import Post from 'components/Post/Post';
import PostPagePost from 'components/Post/PostPagePost';
import { canDeletePost } from 'utils/userHelpers';
import { Mutation } from '@apollo/client/react/components';
import { DELETE_POST_MUTATION } from 'schema/Post/mutations';
import { genericToastAlert } from 'utils/toastHelpers';
import { getTotalNumberOfReplies } from 'helpers/postHelpers';
import TrackingEvent, { TrackingProperty } from 'constants/trackingEvents';
import { rawId } from 'utils/rawId';

let EditPostModal;

interface PostContainerProps {
  additionalClassName?: string | null;
  communityWrapperRef?: React.RefObject<HTMLDivElement>;
  lastCommentRef?: React.RefObject<HTMLDivElement>;
  content: string;
  isPostPage?: boolean;
  mentionedUsers?: MentionedUsersFieldFragment | null;
  post: PostType;
  refetchPostList?: (
    variables?: Partial<OperationVariables>
  ) => Promise<ApolloQueryResult<PostListQuery>>;
  shouldShowTagOnPost?: boolean;
  t: TFunction;
  goToPostListPage?(): void;
  author?: PostListUser | null;
  onPostChange?(): void;
}

export const PostContainer: React.FC<PostContainerProps> = ({
  additionalClassName,
  communityWrapperRef,
  lastCommentRef,
  content,
  mentionedUsers,
  author,
  post,
  t,
  refetchPostList,
  isPostPage = false,
  goToPostListPage,
  shouldShowTagOnPost = false,
  onPostChange,
}) => {
  const { community, currentUser, trackEvent } = useCommunityContext();
  const canUserDeletePost = canDeletePost(currentUser);
  const { width } = useWindowSize();
  const isMobile = width < BREAKPOINT_MD;
  const firstAttachment = post?.postAttachments?.[0];

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  const trackPostEvent = (event) =>
    trackEvent(event, {
      [TrackingProperty.ATTACHMENT]: firstAttachment?.attachmentType,
      [TrackingProperty.PINNED_POST]: !!post.pinnedAt,
      [TrackingProperty.POST_ID]: rawId(post.id),
      [TrackingProperty.POST_ID_ENCODED]: post.id,
      [TrackingProperty.POST_TYPE]: EVENT_POST_TYPES.POST,
    });

  // modal actions
  const handleDeletePost = (event) => {
    document.body.style.overflow = 'hidden';
    setIsOpen(true);
    if (event) event.stopPropagation();
  };

  const closeDeletePostModal = () => {
    document.body.style.overflow = 'visible';
    setIsOpen(false);
  };

  const handleEditPost = (event) => {
    document.body.style.overflow = 'hidden';
    setIsEditModalOpen(true);
    if (event) event.stopPropagation();
  };

  const closeEditPostModal = () => {
    document.body.style.overflow = 'visible';
    setIsEditModalOpen(false);
  };

  const handleConfirmDelete = (mutation) => async () => {
    try {
      const { data: deletePostData, errors: deletePostError } = await mutation({
        variables: { id: post.id },
        refetchQueries: isPostPage ? [] : ['PostList', 'CommunitySpace'],
        update() {
          closeDeletePostModal();
        },
      });

      trackPostEvent(TrackingEvent.DELETE_POST);
      const userErrors = deletePostData?.deletePost?.userErrors;
      if (!userErrors?.length && !deletePostError?.length) {
        toaster.notice(t(SUCCESS_TOAST.DELETE_POST));
      } else {
        genericToastAlert();
      }

      onPostChange?.();

      if (isPostPage) {
        goToPostListPage?.();
      }
    } catch {
      genericToastAlert();
    }
  };

  // modal dynamic renders
  const renderEditPostModal = () => {
    if (!EditPostModal) {
      EditPostModal = dynamic(() => import('components/EditPostModal'), {
        loading: () => (
          <Loader accessibilityLabel={t('common-loading', 'Loading')} />
        ),
      });
    }

    return (
      <EditPostModal
        closeModal={closeEditPostModal}
        communityId={community.id}
        content={content}
        hasAutoFocus={isMobile}
        post={post}
        primaryColor={community.primaryColor}
        s3UploadPath={community.s3UploadPath}
        trackEvent={trackEvent}
      />
    );
  };

  const renderConfirmDeleteModal = (mutate, loading) => {
    const ConfirmDeleteModal = dynamic(() =>
      import('components/ConfirmDeleteModal')
    );

    return (
      <span>
        <ConfirmDeleteModal
          closeAction={closeDeletePostModal}
          confirmDeleteBody={t(
            'components-post-confirm_delete_post',
            'Are you sure you want to delete this post? You cannot undo this action.'
          )}
          confirmDeleteTitle={t('Delete post')}
          deleteAction={handleConfirmDelete(mutate)}
          isConfirmModalOpen={isOpen}
          isLoading={loading}
        />
      </span>
    );
  };

  return (
    <>
      {isPostPage ? (
        <PostPagePost
          additionalClassName={additionalClassName}
          author={author}
          communityWrapperRef={communityWrapperRef}
          content={content}
          handleDeletePost={handleDeletePost}
          handleEditPost={handleEditPost}
          lastCommentRef={lastCommentRef}
          mentionedUsers={mentionedUsers}
          post={post}
          postReactionCount={
            post.postReactions ? post.postReactions.totalCount : 0
          }
          refetchPostList={refetchPostList}
          shouldShowTagOnPost={shouldShowTagOnPost}
          t={t}
          time={post.createdAt}
          totalCommentCount={getTotalNumberOfReplies(post)}
          trackPostEvent={trackPostEvent}
        />
      ) : (
        <Post
          author={author}
          content={content}
          handleDeletePost={handleDeletePost}
          handleEditPost={handleEditPost}
          mentionedUsers={mentionedUsers}
          post={post}
          postReactionCount={
            post.postReactions ? post.postReactions.totalCount : 0
          }
          refetchPostList={refetchPostList}
          shouldShowTagOnPost={shouldShowTagOnPost}
          t={t}
          time={post.createdAt}
          totalCommentCount={getTotalNumberOfReplies(post)}
          trackPostEvent={trackPostEvent}
        />
      )}

      {isEditModalOpen && renderEditPostModal()}

      {canUserDeletePost && isOpen && (
        <Mutation mutation={DELETE_POST_MUTATION}>
          {(mutate, { loading }) => renderConfirmDeleteModal(mutate, loading)}
        </Mutation>
      )}
    </>
  );
};

export default PostContainer;
